import * as t from "../types";

const languageReducer = (state = { value: 'th' }, action) => {
  switch(action.type){
    case t.SET_LANGUAGE:
      return {  ...state, value: action.payload };
    default:
      return { ...state };
    }
}

export default languageReducer;